function Vol(props) {
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-auto">
        <i className="bi bi-volume-down-fill fs-2"></i>
      </div>
      <div className="col-8">
        <input
          type="range"
          onChange={props.onVol} 
          className="form-range pt-2"
          id="customRange1"
        />
      </div>
      <div className="col-auto">
        <i className="bi bi-volume-up-fill fs-2"></i>
      </div>
    </div>
  );
}

export default Vol;
