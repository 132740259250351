import { useEffect, useState } from "react";

function Frek(props) {
  const [form, setForm] = useState("form-control");
  const [msg, setMsg] = useState("invalid-feedback d-block small");

  useEffect(() => {
    function vv() {
      if (props.valor) {
        setForm("form-control is-invalid");
        setMsg("invalid-feedback d-block small");
      } else {
        setForm("form-control");
        setMsg("invalid-feedback small");
      }
    }

    vv();
  }, [props.valor]);
  //eslint-disabled-next-line

  return (
    <div className="row justify-content-center">
      <div className="col-10 col-md-7 mt-4">
        <div className="input-group mb-3">
          <input
            onChange={props.onTxt}
            type="number"
            className={form}
            placeholder="1000 Hz"
            aria-describedby="basic-addon2"
            max="20000"
            min="100"
            maxLength="5"
          />
          <span className="input-group-text" id="basic-addon2">
            Hz
          </span>
          <p className={msg}>Enter a frequency between 100 and 20000 Hz</p>
        </div>
      </div>
    </div>
  );
}

export default Frek;
