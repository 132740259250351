function Play(props) {
  const icon = () => {
    if (props.ico) {
      return <i className="bi bi-play-fill fs-1"></i>;
    } else {
      return <i className="bi bi-pause-fill fs-1"></i>;
    }
  };

  return (
    <div className="d-flex justify-content-center mt-2 mb-3">
      <button className="btn btn-primary" onClick={props.onPlay} disabled={props.enable}>
        {icon()}
      </button>
    </div>
  );
}

export default Play;
