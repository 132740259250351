import { useRef, useState } from "react";
import Play from "./component/Play";
import Vol from "./component/Vol";
import Noise from "./component/white";
import Frek from "./component/Frek";
import Timer from "./component/Countdown";

let ti = null;

function App() {
  const [play, setPlay] = useState(true);
  const [vol, setVol] = useState(50);
  const [volx, setVolx] = useState({
    volume: vol,
  });
  const [txt, setTxt] = useState(1000);
  const [valida, setValida] = useState(false);
  const [h1, setH1] = useState(<span>{txt}Hz</span>);

  const toogle = () => {
    if (!ti) {
      ti = new Noise(parseInt(txt), 0.4);
      //      console.log(parseInt(txt));
    }

    time.current.play(play);
    if (play) {
      //console.log(play)
      ti.play();
      ti.setVolume(volx.volume);
    } else {
      //Noise.stop({volume:0.05})
      //console.log(Noise.o, "re")
      ti.stop();
      //console.log("stop");
    }
    setPlay(!play);
  };

  const realtime = () => {
    if (!play) {
      ti.setVolume(volx.volume);
      //console.log(volx.volume);
        //console.log(vol)
    }
  };

  const time = useRef(null);
  //console.log(time.current);

  function val() {
    //console.log(valida)
    if (valida) {
      setH1(<span>Error</span>);
    } else {
      setH1(<span>{txt}Hz</span>);
    }
  }

  return (
    <div className="container-fluid w-100" style={{ background: "#f4f4f4" }}>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-md-6 col-sm-8 col-11">
          <h1 className="text-center mt-3">{h1}</h1>

          <Frek
            valor={valida}
            onTxt={(e) => {
              if (!play) {
                //console.log("change frecuencia");
                toogle();
                ti = null;
              } else {
                ti = null;
              }

              setTxt(e.target.value);
              if (e.target.value === "") {
                setTxt(1000);
              }
              if (e.target.value === "0") {
                setTxt(1000);
              }

              if (
                parseInt(e.target.value) < 100 ||
                parseInt(e.target.value) > 20000
              ) {
                setValida(true);
              } else {
                setValida(false);
              }
            }}
          />

          <Timer ref={time} start={play} />

          <Play
            enable={valida}
            ico={play}
            onPlay={() => {
              toogle();
              val(valida);
            }}
          />

          <Vol
            onVol={(e) => {
              setVol(e.target.value);
              setVolx({ volume: (1 / 100) * parseInt(e.target.value) });
                //console.log(vol)
              //realtime();
            }}
          />

          <p className="text-center">{vol}% {realtime()}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
