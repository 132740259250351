import React, { Component } from "react";

import Countdown, { CountdownApi, zeroPad } from "react-countdown";

// Random component
const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <h4 className="text-primary text-center">
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </h4>
    );
  }
};

export default class CountdownApiExample extends Component {
  countdownApi: CountdownApi | null = null;
  state = { date: Date.now() + 1.08e7 };

  handleStartClick = () => {
    this.countdownApi && this.countdownApi.start();
  };

  handlePauseClick = () => {
    this.countdownApi && this.countdownApi.pause();
  };

  handleResetClick = () => {
    this.setState({ date: Date.now() + 1.08e7 });
  };

  handleUpdate = () => {
    this.forceUpdate();
  };

  setRef = (countdown: Countdown | null) => {
    if (countdown) {
      this.countdownApi = countdown.getApi();
    }
  };

  isPaused() {
    return !!(this.countdownApi && this.countdownApi.isPaused());
  }

  isCompleted() {
    return !!(this.countdownApi && this.countdownApi.isCompleted());
  }

  play(x) {
    if (x) {
      this.handleStartClick();
      //console.log("start");
    } else {
      this.handlePauseClick();
      //console.log("pause")
    }
  }

  render() {
    return (
      <div className="alert-info mt-2 mb-3 p-2">
        <h3 className="text-center">Zeitdauer</h3>
        <Countdown
          key={this.state.date}
          ref={this.setRef}
          date={this.state.date}
          onMount={this.handleUpdate}
          onStart={this.handleUpdate}
          onPause={this.handleUpdate}
          onComplete={this.handleUpdate}
          autoStart={false}
          renderer={renderer}
        />
      </div>
    );
  }
}
